import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartInterval: {
      type: String,
      default: "Daily",
    },
    chartUnits: {
      type: String,
      default: "",
    },
    secondaryChartUnits: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      options: {
        plugins: {
          legend: {
            position: "top",
            padding: 25,
            labels: {
              font: {}
            }
          },
          datalabels: { // Disable datalabels for this chart
            display: false
          },
          tooltip: {
            enabled: true,
            intersect: false,
            callbacks: {
              title: (tooltipItems) => {
                // Extract the date from the tooltip item
                const dateStr = tooltipItems[0].label; // Assuming the label is the date
                if (this.chartInterval === "Weekly") {
                    return this.getWeekNumber(dateStr); // Format the date
                } else {
                    return this.formatDate(dateStr); // Format the date
                }
              },
              label: (tooltipItem) => {
                  // Create a formatter that preserves all decimals
                  const formattedValue = new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4, // A high number to ensure all decimals are shown
                  }).format(tooltipItem.raw);

                  return `${tooltipItem.dataset.label}: ${formattedValue}`; // Display the formatted value
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
           y: {
            stacked: true, // Stack the y-axis
            position: 'left',
            grid: {
              display: true,
              color: "#e4e6e8",
            },
            ticks: {
              display: true, // Keep tick labels visible
              font: {
                size: 10, // Increase tick label font size if needed
              },
              callback: (value) => {
                const formattedValue = new Intl.NumberFormat('en-US').format(value);
                return `${formattedValue}`; // Format the tick labels
              }
            },
            title: {
              display: true, // Enable the title
              text: this.chartUnits || "", // Use the chartUnits prop or a default value
              font: {
                size: 18, // Increase font size for the title
                weight: '600', // Make the title bold
              },
              padding: { top: 20, bottom: 0 }, // Adjust padding if needed
              color: "#64676c", // Set color for the title
            },
          },
          x: {
            stacked: true, // Stack the x-axis (to enable stacking behavior)
            grid: {
              display: false
            },
            ticks: {
              maxTicksLimit: 8,
              font: {
                size: 10
              },
              maxRotation: 0,
              minRotation: 0,
              callback: (value, index) => {
                  const dateStr = this.chartData.labels[index]; // Use labels array for dates
                  if (this.chartInterval === "Weekly") {
                    const yearWeek = `${this.getWeekNumber(dateStr)}`;
                    return yearWeek; // Format for weekly intervals
                  } else {
                    return this.formatDate(dateStr); // Default format
                  }
              }
            },
          },
        }
      }
    }
  },
  methods: {
    getWeekNumber(dateStr) {
      // Create a Date object from the ISO string
      const date = new Date(dateStr);

      // Function to calculate the ISO week number
      function calculateWeekNumber(date) {
        // Set date to the nearest Thursday (ISO standard)
        const tempDate = new Date(date.getTime());
        tempDate.setHours(0, 0, 0, 0);
        tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));

        // January 4th is always in the first week of the year
        const firstThursday = new Date(tempDate.getFullYear(), 0, 4);

        // Calculate full weeks to the date
        const weekNumber = Math.ceil(
          ((tempDate - firstThursday) / 86400000 + 1) / 7
        );

        return weekNumber;
      }

      // Get the week number and year
      const weekNumber = calculateWeekNumber(date);
      const year = date.getFullYear();

      // Concatenate year and week number (e.g., "2446")
      return `${year % 100}${weekNumber.toString().padStart(2, "0")}`;
    },
    formatDate(dateStr) {
      // Create a Date object from the ISO string
      const date = new Date(dateStr);

      // Helper function to get ordinal suffix for the day
      const getOrdinalSuffix = (day) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const v = day % 100;
        return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
      };

      // Format options for date
      const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };

      // Format options for date and time
      const dateTimeOptions = {
        ...dateOptions,
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // Change to true if you want 12-hour format
      };

      // Determine the appropriate format based on chartInterval
      const options = this.chartInterval === "Hourly" ? dateTimeOptions : dateOptions;

      // Get the formatted day with ordinal suffix
      const formattedDate = date.toLocaleDateString('en-GB', options);
      const dayWithOrdinal = getOrdinalSuffix(date.getDate());

      // Replace the day in the formatted date with the day having the ordinal suffix
      return formattedDate.replace(date.getDate(), dayWithOrdinal);
    }

  },
  watch: {
    'chartData.ymax': function(newVal, oldVal) {
      // Check if newVal is null or undefined
      if (newVal === null || newVal === undefined) {
        console.log(`ymax reset to null from ${oldVal}`);
        this.options.scales.y.max = null; // Set to null
      } else if (!isNaN(parseInt(newVal))) {
        console.log(`ymax changed from ${oldVal} to ${newVal}`);
        this.options.scales.y.max = parseInt(newVal); // Update the max value in options
      }
      this.renderChart(this.chartData, this.options); // Re-render the chart to reflect changes
    },
    'chartData.ymin': function(newVal, oldVal) {
      // Check if newVal is null or undefined
      if (newVal === null || newVal === undefined) {
        console.log(`ymin reset to null from ${oldVal}`);
        this.options.scales.y.min = null; // Set to null
      } else if (!isNaN(parseInt(newVal))) {
        console.log(`ymin changed from ${oldVal} to ${newVal}`);
        this.options.scales.y.min = parseInt(newVal); // Update the min value in options
      }
      this.renderChart(this.chartData, this.options); // Re-render the chart to reflect changes
    }
  },
  mounted() {
      // Ensure that the datasets have 'fill: true' to create the area effect
      if (this.chartData && this.chartData.datasets) {
        this.chartData.datasets.forEach((dataset) => {
          // Set pointRadius based on chartInterval
          dataset.pointRadius = this.chartInterval === "Hourly" ? 1 : 2; // Set to 1 if Hourly, otherwise 3
          dataset.pointHoverRadius = 5; // Set hover size to 5px

          // Set point color
          dataset.pointBackgroundColor = '#7a8185'; // Change the point color based on chartInterval

          // Optional: Set point border color if needed
          dataset.pointBorderColor = '#7a8185';
        });
      }

      this.renderChart(this.chartData, this.options);
    }
}
